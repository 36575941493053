.pacientes {
    height: calc(100vh - 160px);
    overflow-y: scroll;

}

.link-button {
    border: none;
    color : #2a8a54;
    text-decoration: none;
    background-color: transparent;
}

.link-button:hover {
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;

}

.link-button:focus {
    outline: none;
}

.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  /*cursor: pointer;*/
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  /*cursor: pointer;*/
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}



/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tabla-mov-min{
  overflow-y: scroll;
  height: 380px;
}
.tabla-mov-max{
  overflow-y: scroll;
  height: 700px;
}

.tabla-mov-min th{
    position: sticky;
    top: 0;
    z-index: 10;
}
.select-1 {
    z-index:20;
}
.select-2 {
    z-index:15;
}
.select-3 {
    z-index:10;
}

.clickable-tr:hover {
    background: #d5d5d5;
    cursor: pointer;
}

.base-iva {
    width: 100px;
}

.no-overflow {
    overflow: hidden;
}