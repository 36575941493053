.lista-notas {
    height:440px;
    overflow-y:auto;
}
.lista-notas-max {
    height:650px;
    overflow-y:auto;
}

.nota-header {
    background-color: #ddf1f8;
}

.btn-link:focus {
    outline: none;
}

.select-1 {
    z-index: 9999;
}
