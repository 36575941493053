.tarifa-header {
    background-color: #ddf1f8;
}

.link-button {
    border: none;
    color : #2a8a54;
    text-decoration: underline;
}

.link-button:hover {
    cursor: pointer;
    background-color: #e7e7e5;

}

.link-button:focus {
    outline: none;
}

.updating {
    position:absolute;
    top: 100px;
    left: 400px;
}

.no-overflow {
    overflow: hidden;
}