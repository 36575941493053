.item-dark {
    color: #f8f9fa;

}

.item-dark:hover {
    color: #212529;

}

.shadow-separation {
    background-image: linear-gradient(rgb(120, 120, 120, 0.3), rgb(120, 120, 120, 0));
    height: 10px

}


.link-button:hover {
    cursor: pointer;
    background-color: #e7e7e5;

}

.link-button:focus {
    outline: none;
}

.select-0 {
    z-index:    30;
}


