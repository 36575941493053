.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}
.select-search__select {
    position: absolute;
    top:35px;
    left: 0px;
    z-index: 10;
    background-color: #FFFFFF;
    /*width: 580px;*/
    overflow-y: auto;
    overflow-x: visible;
    max-height: 150px;
    border: 1px solid #0f0f0f;
    border-radius: 0 0 3px 3px;
}
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border:  1px solid #d0d0d0;
    /*box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);*/
    border-radius: 3px;
    /*outline: none;*/
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__options {
    list-style: none;
}
.select-search__option.is-selected {
    background: #2FCC8B;
    color: #fff;
}

.select-search__option, .select-search__not-found {
    display: block;
    /*height: 36px;*/
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search *, .select-search *::after, .select-search *::before {
    box-sizing: inherit;
}
* {
    margin: 0;
    padding: 0;
}



.select-search {
    /*width: 300px;*/
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *, .select-search *::after, .select-search *::before {
    box-sizing: inherit;
}

.select-search *, .select-search *::after, .select-search *::before {
    box-sizing: inherit;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}
.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

