.comentarios {
    height: 80px;
    overflow-y: auto;
    border: 1px solid #ced4da;
    background-color: #e9ecef;
}
.horas {
    width: 25px;
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
}
.btn-copia-dia {
    margin-top: 140px;

}

.fc-dayGridMonth-view {
    height:400px;
    overflow-y: auto;
}
.fc-dayGridWeek-view {
    height:160px;
    overflow-y: auto;
}
.fc-dayGridDay-view {
    height:160px;
    overflow-y: auto;
}
.calendario {
    height:240px;
    overflow-y: auto;
}
.subtitulo {
    background-color: #6bc9ee;
    color: white;
}
.asistente-inline {
    min-height: 60px;
}
.asistentes {
    height: 640px;
    overflow-y: auto;
    overflow-x: hidden;
}

.asistentes-area {
    height: 300px;
    overflow: auto;
    border: 1px solid #d5d5d5;
}

.asistentes-area-expanded {
    height: 600px;
    overflow: auto;
    border: 1px solid #d5d5d5;
}

.btn-nofocus {
  outline: none;
  box-shadow: none;
  border: none;
}

.asistente-selected {
  background-color: #e2e2b9;

}
.texto-terapia {
    height: 600px;
    overflow-y: auto;
}

.btn-link:focus {
    outline: none;
}
