.area-opciones {
    background-color: #e4dbef;
}

.imagen-fondo {
    opacity: 0.3;
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:50px;

}
.area-contactos {
    max-height: 330px;
    overflow-y: auto;

}

.wrap-word {
    overflow-wrap: break-word;
}

.img-identificacion {
    width: 300px;
    height: 300px;
}
.lista-terapias {
    height: 880px;
    overflow-y: auto;
}
.subtitulo {
    background-color: #6bc9ee;
    color: white;
}
