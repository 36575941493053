.area-opciones {
    background-color: #f8f8f8;
}

.capitalize {
    text-transform: capitalize;
}

.separacion-log {
    margin-left: 15px;
    border-left: 2px solid #d1cde9;
    height: 20px;
}
.fecha-log {
    padding-top: 6px;
    background-color: #a4bbf3;
    color:white;
}

.log-cambio {
    margin-top: 50px;
    height: 380px;
    overflow-y: auto;
}
.pestanas {
    position: relative;
}
.paciente-icono {
    position: absolute;
    right:10px;
    top:0px;
    width: 395px;
    z-index: 10;
}
.volver-icono {
    position: absolute;
    left:10px;
    top:0px;
    width: 50px;
    z-index: 10;
}

.area-onHold {
    pointer-events: none;
    opacity: 0.5;
}