
.link-td:hover {
    cursor: pointer;
    color: #1A01CC;
}
.link-td {
   text-decoration: underline;
}
link-td:focus {
    outline: none;
}

.notas-area {
    height: 80px;
    overflow: auto;
}
.notas-area p{
    line-height:60%;
}


.medicacion-tomas {
    width: 25px;
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
}

.medicamento-area {
    border-bottom: 3px solid #d5d5d5;
    border-right: 1px solid #d5d5d5;

}

.medicamentos-area {
    height: 270px;
    overflow: auto;
    border: 1px solid #d5d5d5;
}

.medicamentos-area-expanded {
    height: 490px;
    overflow: auto;
    border: 1px solid #d5d5d5;
}


.titulos-prescripcion {
    background-color: #6bc9ee;
    color: white;
}

.foto-inline {
    width: 120px;
}
.searchInline-area{
    display: block;
    height: 500px;
    overflow: auto;
}

.searchInline-linea:hover {
    cursor: pointer;
    background-color: #d5d5d5;
}

.searching {
    width:800px;
    height: 500px
}
.spinner-border {
    width:100px;
    height:100px;
}

.uso-medicacion.transbox {

background-color: #ffffff;
opacity: 0.9;

}
.drag-item {
    cursor: grabbing;
}

.bg-no-ultima {
    background-color: #f6f6c0;
}

.trash-medicacion {
    position: absolute;
    top: 290px;
    right: -200px;
    transition: right 1s;

}
.show-trash {
    right: 0px;
}

.img-dragging {
    opacity: 0.5;
}