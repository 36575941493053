.tableFixHead thead th { position: sticky; top: 0; }

.medicamentosArea {
    height: 450px;
    overflow-y: auto;
}
.medicamentosArea-max {
    height: 650px;
    overflow-y: auto;
}
.link-button:focus {
    outline: none;
}

