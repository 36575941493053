.dropArea {
    height: 800px;
    width: 800px;
    background-color: #f9f2f4;
}
.dropArea:hover {
    cursor: pointer;
}
.webArea {
    height: 470px;
    width: 800px;
    background-color: #f9f2f4;
}

.logo-img {
    height: 35px;

}